import React, { useEffect } from 'react';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/RecipeDetailTitle.js";
import Features from "components/features/ThreeColSimple.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default () => {
  const [loading, setLoading] = React.useState(true);
  const [recipe, setRecipe] = React.useState(null);  

  const { recipe_id } = useParams();
  const access_token = localStorage.getItem('access_token');
  const navigate = useNavigate();
  
  useEffect(() => {
    const access_token = localStorage.getItem('access_token');
    const fetchData = async () => {
      try {
        console.log('fetching data for recipe', process.env.REACT_APP_BACKEND_URL + `/recipes/${recipe_id}`);
        const result = await axios.get(process.env.REACT_APP_BACKEND_URL + `/recipes/${recipe_id}`,
        {
          headers: {
            'Authorization': `Bearer ${access_token}`
          }}
        );
  
        if (result.status === 401) {
          navigate('/login');
        } else {
          setRecipe(result.data);
          setLoading(false);
        }
      } catch (error) {
        console.error('Failed to fetch data', error);
        navigate('/login');
      }
    };
  
    fetchData();
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>;  
  }


  return (
    <AnimationRevealPage>
      <Hero recipe={recipe}/>
      <Features recipe={recipe} />
      <Footer />
    </AnimationRevealPage>
  );
};