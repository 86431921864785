import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import CreatableSelect from 'react-select/creatable';
import axios from "axios";
const Form = tw.form`mx-auto max-w-md`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const TextArea = tw.textarea`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Button = tw.button`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2`;
const ButtonSubmit = tw(Button)`w-full py-2 flex items-center justify-center text-white bg-primary-500`;
const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Amazing Features", subheading = "Features", description = "" }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */
  const access_token = localStorage.getItem('access_token');
  const navigate = useNavigate();

  
  const [formData, setFormData] = useState({
    name: "",
    categories: [],
    ingredients_text: "",
    procedure: "",
    image_path: ""
  });

  const [categories, setCategories] = useState([]);
 const [selectedImage, setSelectedImage] = useState('No file selected');
  const [imageAlert, setImageAlert] = useState(false);
 
useEffect(() => {
  axios.get(process.env.REACT_APP_BACKEND_URL + `/recipes/categories`, {
    headers: {
      'Authorization': `Bearer ${access_token}`
    }
  })
  .then(response => setCategories(response.data))
  .catch(error => console.error(error));
}, [navigate,access_token]);

  const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  

    const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedImage === 'No file selected') {
        setImageAlert(true);
        return
    }

    const formData2 = new FormData();
    formData2.append('name', formData.name);
    formData2.append('categories', formData.categories);
    formData2.append('ingredients_text', formData.ingredients_text);
    const procedureObjects = procedures.map(procedure => ({ "procedure": procedure }));
    formData2.append('procedure', JSON.stringify(procedureObjects));
    formData2.append('image_path', formData.image_path);

    // Post the form data
    axios.post(process.env.REACT_APP_BACKEND_URL + '/recipes_with_text/', formData2,
        {headers: {
            'Authorization': `Bearer ${access_token}`
        }
    })
    .then(response => {
      if (response.status !== 201) {
          if (response.status === 401) {
              window.location.href = '/login';
          } else {
              throw new Error(`Request failed with status ${response.status} and message ${response.data}`);
          }
      } else {
          localStorage.setItem('postSuccess', 'true');
          window.location.href = '/my-recipes';
      }
  })
    .catch(error => {
        console.error('Error:', error);
        alert('Something went wrong with input fields');
    });
};


  const handleSelectChange = (selectedOptions) => {
    setFormData({ ...formData, categories: selectedOptions.map(option => option.value) });
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
      padding: '8px',
      marginTop: '16px',
      fontSize: 'small',
      backgroundColor: '#F7FAFC',
      borderColor: '#CBD5E0',
      borderRadius: '0.375rem',
      '&:hover': {
        borderColor: '#A0AEC0',
      },
      '&:focus': {
        borderColor: '#A0AEC0',
        backgroundColor: '#FFFFFF',
        boxShadow: 'none',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#A0AEC0',
    }),
  };

  const categoryOptions = categories.map(category => ({ value: category, label: category }));

  const [procedures, setProcedures] = useState(['']);
  const handleProcedureChange = (e, index) => {
    const newProcedures = [...procedures];
    newProcedures[index] = e.target.value;
    setProcedures(newProcedures);
  };
  const addProcedure = () => {
    setProcedures([...procedures, '']);
  };

  const handleImageUpload = (event) => {

    if (event.target.files.length > 0) {
      setSelectedImage(event.target.files[0].name);
      setImageAlert(false);
    } else {
      setImageAlert(true);
    }
  
    const file = event.target.files[0];
    const reader = new FileReader();
    
    reader.onloadend = () => {
      // Use reader.result as the image path
      setFormData({ ...formData, image_path: file });
    };
  
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
      
      </ThreeColumnContainer>


<Form onSubmit={handleSubmit}>
        <Input required type="text" name="name" placeholder="Name your recipe!" onChange={handleChange} />
        <CreatableSelect   placeholder="Select multiple categories for your recipe!"
 required name="categories" isMulti  styles={customStyles} options={categoryOptions} onChange={handleSelectChange}/>
        <TextArea required name="ingredients_text"   placeholder={"Insert a list of ingredients like: \n\ningredient_X amount_X \nEggs 2 \nSugar 2tbs"} onChange={handleChange}   style={{ height: '25vh' }} />
        {procedures.map((procedure, index) => (
  <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
    <TextArea
    required
      name={`procedure${index}`}
      placeholder={`Cooking Step ${index + 1} \n\nFirst, boil the eggs. Place them in a pot and cover them with cold water by 1 inch. Bring the water to a boil over high heat.`}
      value={procedure}
      style={{ height: '20vh', flex: '1' }}
      onChange={(e) => handleProcedureChange(e, index)}
    />
    {index === procedures.length - 1 && (
      <Button style={{ width: '25%' }} type="button" onClick={addProcedure}>
        Add Another Cooking Step
      </Button>
    )}
  </div>
))}
<div style={{ display: 'flex', alignItems: 'center' }}>
  <Button type="button" onClick={() => document.getElementsByName('image_path')[0].click()}>
    Upload Cover Photo
  </Button>
  <Input type="text" readOnly value={selectedImage} />
  <input
  accept="image/png, image/jpeg"
    type="file"
    name="image_path"
    style={{ display: 'none' }}
    onChange={(e) => {
      handleImageUpload(e);
    }}
  />
</div>
        <ButtonSubmit type="submit">Submit</ButtonSubmit>
        {imageAlert &&       <Alert severity="error">Please select a cover photo.</Alert>
}
      </Form>
      <DecoratorBlob />
    </Container>
  );
};

