import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
// import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Tooltip from '@mui/material/Tooltip';
import {red, grey} from '@mui/material/colors'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import DeleteIcon from '@mui/icons-material/Delete';


export default function FloatingActionButtons({recipe}) {
    const value = 400;
    const access_token = localStorage.getItem('access_token');
    const user_id = localStorage.getItem('user_id');
    const [userRating, setUserRating] = useState(recipe.ratings[user_id] || 0);
    const [copied, setCopied] = useState(recipe.copied_by.includes(user_id));
    const [iconPublic, setIconPublic] = useState(recipe.public ? 1 : 0);
    const navigate = useNavigate();
    const handleClick = async () => {
      try {
        
        const result = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/recipe/${recipe._id}/rating`,
          { "rating": userRating === 0 ? 1 : 0 },
          {
            headers: {
              'Authorization': `Bearer ${access_token}`
            }
          }
        );

        setUserRating(result.data["total_ratings"]);
      } catch (error) {
        console.error(error);
        navigate('/login');
      }
    };

    const copyRecipe = async () => {
      try {
        const result = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/recipe/${recipe._id}/copy`,
          {"recipe_id": recipe._id},
          {
            headers: {
              'Authorization': `Bearer ${access_token}`
            }
          }
        );
        setCopied(result.data['copied']);
      } catch (error) {
        console.error(error);
        navigate('/login');
      }
    };

    const handleVisibilityChange = async (event) => {
      event.preventDefault();
      try {
        await axios.post(
          process.env.REACT_APP_BACKEND_URL + '/recipe/visibility',
          recipe,
          {
            headers: {
              'Authorization': `Bearer ${access_token}`
            }
          }
        ).then(response => {
          setIconPublic(response.data.public ? 1 : 0);

       } )
      } catch (error) {
        console.error('Error changing visibility:', error);
        navigate('/login');
      }
    };

    
  

  return (
    <Box sx={{ '& > :not(style)': { m: 1 } }}>
      {user_id !== recipe.user_id &&
      <Fab style={{ backgroundColor: copied !== false ? red[value] : grey[value] }} onClick={copyRecipe} aria-label="add">
      <Tooltip title={copied === false ? "Save to your recipes!" : "Uncopy recipe"}>
        {copied !== false ? <DeleteIcon style={{ color: "white" }} /> : <AddIcon style={{ color: "white" }} />}
        </Tooltip>
      </Fab>
}
      {/* <Fab  aria-label="edit">
      <Tooltip title="Edit this recipe">
        <EditIcon style={{ color: "white" }} />
        </Tooltip>
      </Fab> */}
            {user_id !== recipe.user_id &&

      <Fab style={{ backgroundColor: userRating === 1 ? red[value] : grey[value] }} aria-label="edit" onClick={handleClick}>
      <Tooltip title={userRating !== 1 ? "Like this recipe" : "Remove like" }>
        <FavoriteIcon style={{ color: "white" }} />
        </Tooltip>
      </Fab>}
      {user_id === recipe.user_id &&

      <Fab style={{ backgroundColor: iconPublic === 1 ? red[value] : grey[value] }} onClick={handleVisibilityChange}  aria-label="edit">
      <Tooltip title={iconPublic !== 1 ? "Make public" : "Remove from public"}>
        {iconPublic === 1 ? <PublicIcon style={{ color: "white" }} /> : <PublicOffIcon style={{ color: "white" }} />}
        </Tooltip>
      </Fab>}
    </Box>
  );
}