import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import Footer from "components/footers/MiniCenteredFooter.js";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Insert Recipe</Subheading>}
        heading={
          <>
            Lets import your <HighlightedText>Recipe!</HighlightedText>
          </>
        }
      />
      <Footer />
    </AnimationRevealPage>
  );
}
