import { decodeToken, isExpired } from "react-jwt";

function decodeAndValidateToken(token, secretKey) {
    try {
        const decodedToken = decodeToken(token);
        const tokenIsExpired = isExpired(token);

        if (tokenIsExpired) {
            throw new Error('Token is expired');
        }

        return decodedToken;
    } catch (err) {
        throw err;
    }
}

export default decodeAndValidateToken;