import React, { useState, useEffect } from 'react';
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import axios from 'axios';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import GridFilter from 'components/cards/GridFilter';
import foto_front from 'images/foto_front.avif';

export default () => {
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const imageCss = tw`rounded-4xl`;
  const [recipes, setRecipes] = useState([]);
  const [alert, setAlert] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    if (localStorage.getItem('postSuccess') === 'true') {
      
      setAlert(true);
      localStorage.removeItem('postSuccess');
    }
  }, []);

useEffect(() => {
  const access_token = localStorage.getItem('access_token');
  const fetchData = async () => {
    try {
      const result = await axios.get(process.env.REACT_APP_BACKEND_URL + '/recipes/',
      {
        headers: {
          'Authorization': `Bearer ${access_token}`
        }}
      );

      setRecipes(result.data);
    } catch (error) {
      console.error('Failed to fetch data', error);
      navigate('/login');
    }
  };

  fetchData();
}, [navigate]);
  
  return (
    <div>
    {alert && <Alert severity="success">Recipe inserted successfully!</Alert>}
    <AnimationRevealPage>
      <Hero
        heading={<>Keep all your favorite recipes <HighlightedText>in one place.</HighlightedText></>}
        description="Organize, Share, and Savor Every Recipe in Your Culinary Collection."
        imageSrc={foto_front}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText={""}
        watchVideoButtonText="Meet The Chefs"
      />
      
      <GridFilter
        heading={
          <>
            Checkout all <HighlightedText>menus.</HighlightedText>
          </>
        }
        tabs={
          recipes
        }
        from_my_recipes={false}
      />
      <Footer />
    </AnimationRevealPage>
    </div>)
}
