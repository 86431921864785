import React, { useState } from 'react';
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import CreatableSelect from 'react-select/creatable';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Select from 'react-select';

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-red-400 mr-1`}
  }
`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;
const TabControl = styled.div`
  ${tw`cursor-pointer px-3 py-1 mt-1 sm:mt-0 sm:mr-1 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-xs sm:text-sm inline-block text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${tw`bg-primary-500 text-white border border-blue-500 rounded`}
`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const SelectContainer = tw.div`w-full sm:w-64 px-4`;

export default ({
  heading = "Checkout the Menu",
  uniqueCategories = [],
  tabs = {
    Starters: [
      {
        imageSrc:
          "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
        title: "Veg Mixer",
        content: "Tomato Salad & Carrot",
        price: "$5.99",
        rating: "5.0",
        reviews: "87",
        url: "#"
      },
      
    ],
    Main: getRandomCards(),
    Soup: getRandomCards(),
    Desserts: getRandomCards()
  },
  from_my_recipes = false
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const [categoriesFilter, setCategoriesFilter] = useState([]);
  const [recipeNameFilter, setRecipeNameFilter] = useState([]);
  const [usernameFilter, setUsernameFilter] = useState([]);
  const [ownedFilter, setOwnedFilter] = useState(['copied', 'owned']);

  const handleChange = (selectedOption, { name }) => {
    switch (name) {
      case 'categories':
        const selectedValues = selectedOption.map(option => option.value);
        setCategoriesFilter(selectedValues);
        break;
      case 'recipename':
        setRecipeNameFilter([selectedOption['value']]);
        break;
      case 'username':
        setUsernameFilter([selectedOption['value']]);
        break;
      case 'owned':
        setOwnedFilter(Array.isArray(selectedOption) ? selectedOption.map(option => option.value) : [selectedOption.value]);
        break;
      default:
        break;
    }
  };
const user_id = localStorage.getItem('user_id');
  const categoryList = uniqueCategories.map(category => ({ value: category, label: category }));
const filteredTabs = tabs.filter(tab => {
  const isOwnedSelected = ownedFilter.includes('owned');
  const isCopiedSelected = ownedFilter.includes('copied');
  console.log(isCopiedSelected,isOwnedSelected)
  return (
    (categoriesFilter.length === 0 || tab.categories.some(val => categoriesFilter.map(v => v.toLowerCase()).includes(val.toLowerCase()))) &&
    (recipeNameFilter.length === 0 || recipeNameFilter.some(val => tab.name.toLowerCase().includes(val.toLowerCase()))) &&
    (usernameFilter.length === 0 || usernameFilter.some(val => tab.display_name.toLowerCase().includes(val.toLowerCase()))) &&
    ((isOwnedSelected && isCopiedSelected) || (isOwnedSelected && tab.user_id === user_id) || (isCopiedSelected && tab.user_id !== user_id))
  );
});
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
        </HeaderRow>
        <ThreeColumnContainer>
  {from_my_recipes && (
    <SelectContainer>
    
<Select
  onChange={handleChange}
  options={[{ value: 'copied', label: 'Copied' }, { value: 'owned', label: 'Owned' }]}
  placeholder="Copied?"
  name="owned"
  isMulti
  defaultValue={[
    { value: 'copied', label: 'Copied' },
    { value: 'owned', label: 'Owned' },
  ]}
/>
    </SelectContainer>
  )}
  <SelectContainer>
    <CreatableSelect
      onChange={handleChange}
      options={categoryList}
      placeholder="Filter on categories!"
      name="categories"
      isMulti

    />
  </SelectContainer>
  <SelectContainer>
    <CreatableSelect
      onChange={handleChange}
      options={categoryList}
      placeholder="Filter on recipe name!"
      name="recipename"
      isClearable

    />
  </SelectContainer>
  <SelectContainer>
    <CreatableSelect
      onChange={handleChange}
      isClearable
      options={categoryList}
      placeholder="Filter on username!"
      name="username"
    />
  </SelectContainer>
</ThreeColumnContainer>

        
      <TabContent>
  
        
            {filteredTabs.map((card, index) => (
              <CardContainer key={index}>
                <Card className="group" href={`/recipes/${card._id}`} initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer imageSrc={process.env.REACT_APP_BACKEND_URL + `/recipes/${card._id}/image`} >
                  <CardRatingContainer>
                  <CardRating>
        <FavoriteIcon />
        {Object.values(card.ratings).filter(rating => rating === 1).length}
</CardRating>

                    </CardRatingContainer>
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton>Checkout!</CardButton>
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.name}</CardTitle>
                    <CardContent>By {card.display_name}</CardContent>
                    <CardContent>
                      {card.categories.map((category, index) => (
  <TabControl key={index}>{category}</TabControl>
))}
                      </CardContent>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
            </TabContent>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
      
    </Container>
  );
};

/* This function is only there for demo purposes. It populates placeholder cards */
const getRandomCards = () => {
  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Chicken Chilled",
      content: "Chicken Main Course",
      price: "$5.99",
      rating: "5.0",
      reviews: "87",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1582254465498-6bc70419b607?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Samsa Beef",
      content: "Fried Mexican Beef",
      price: "$3.99",
      rating: "4.5",
      reviews: "34",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1565310022184-f23a884f29da?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Carnet Nachos",
      content: "Chilli Crispy Nachos",
      price: "$3.99",
      rating: "3.9",
      reviews: "26",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1534422298391-e4f8c172dddb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Guacamole Mex",
      content: "Mexican Chilli",
      price: "$3.99",
      rating: "4.2",
      reviews: "95",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1550461716-dbf266b2a8a7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Chillie Cake",
      content: "Deepfried Chicken",
      price: "$2.99",
      rating: "5.0",
      reviews: "61",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1476224203421-9ac39bcb3327??ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Nelli",
      content: "Hamburger & Fries",
      price: "$7.99",
      rating: "4.9",
      reviews: "89",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1455619452474-d2be8b1e70cd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Jalapeno Poppers",
      content: "Crispy Soyabeans",
      price: "$8.99",
      rating: "4.6",
      reviews: "12",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1473093226795-af9932fe5856?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Cajun Chicken",
      content: "Roasted Chicken & Egg",
      price: "$7.99",
      rating: "4.2",
      reviews: "19",
      url: "#"
    }
  ];

  // Shuffle array
  return cards.sort(() => Math.random() - 0.5);
};
