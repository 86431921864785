import React from "react";
//eslint-disable-next-line

import Header from "../headers/light.js";



export default ({ roundedHeaderButton }) => {
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
    </>
  );
};
