import React, { useRef, useState, useEffect } from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import Header, { LogoLink, NavLinks, NavLink as NavLinkBase } from "../headers/light.js";
import {css} from "styled-components/macro"; //eslint-disable-line
import VariantButtonGroup from "./MoreMenu.js";

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;

const NavLink = tw(NavLinkBase)`
  sm:text-sm sm:mx-6
`;

const DescriptionContainer = styled.div`
  max-height: 20vh;
  overflow: hidden;
`;

const Container = tw.div`relative -mx-8 -mt-8`;
const TwoColumn = tw.div`flex flex-col lg:flex-row bg-gray-100`;
const LeftColumn = tw.div`ml-8 mr-8 xl:pl-10 py-8`;
const RightColumn = styled.div`
  background-image: url(${props => props.bgImage});
  ${tw`bg-green-500 bg-cover bg-center xl:ml-24 h-96 lg:h-auto lg:w-1/2 lg:flex-1`}
`;

const Content = tw.div`mt-24 lg:mt-24 lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-3xl sm:text-5xl md:text-6xl lg:text-5xl font-black leading-none`;
const Paragraph = tw.p`max-w-md my-8 lg:my-5 lg:my-8 sm:text-lg lg:text-base xl:text-lg leading-loose overflow-hidden break-words`;
const Actions = styled.div`
  ${tw`mb-8 lg:mb-0`}
  .action {
    ${tw`text-center inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
    ${tw`bg-primary-500 text-gray-100 hover:bg-primary-700`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800`}
  }
`;

const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const DividerTextContainerStick = styled.div`
  position: sticky;
  top: 50vh;
`;

export default ({
  recipe,
  navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Home</NavLink>
      <NavLink href="/insert-recipe">Insert Recipe</NavLink>
    </NavLinks>
  ],
  heading = (
    <>
      This is your
      <wbr />
      <br />
      <span tw="text-primary-500">{recipe.name}</span>
    </>
  ),
  description = Object.entries(recipe.ingredients).map(([key, value]) => <><span>{`${key}`} </span><span tw="text-primary-500"> {`${value}`}</span><br /></>),
  primaryActionUrl = "#",
  primaryActionText = "Rate Recipe",
  secondaryActionUrl = "#",
  secondaryActionText = "Make public"
}) => {

const [isExpanded, setIsExpanded] = useState(false);
const [isOverflowing, setIsOverflowing] = useState(false);
const paragraphRef = useRef();
const [recipeRefresh, setRecipe] = useState(recipe); //eslint-disable-line


useEffect(() => {
  const element = paragraphRef.current;
  const sixtyVh = window.innerHeight * 0.3;
  if (element.getBoundingClientRect().height > sixtyVh) {
    setIsOverflowing(true);
  } else {
    setIsOverflowing(false);
  }
}, [description]);


return (
    <Container>
      <TwoColumn>
        
      <LeftColumn style={{ maxHeight: isExpanded ? 'none' : '60vh', overflow: 'hidden' }}>
        
          <StyledHeader links={navLinks} collapseBreakpointClass="sm" />

          <Content onClick={() => setIsExpanded(!isExpanded)}>

            <Heading>{heading}    </Heading>
        

            
<DescriptionContainer style={{ maxHeight: !isExpanded ? '20vh' : '60vh', overflow: 'hidden' }}>
  <Paragraph ref={paragraphRef}>{description}</Paragraph></DescriptionContainer>
{isOverflowing && (
  <DividerTextContainerStick>
    <DividerText
      style={{ paddingTop: !isExpanded ? "10%" : "0%" }}
    >
      {!isExpanded ? "Show all ingredients ..." : "Show less ingredients ..."}
    </DividerText>
  </DividerTextContainerStick>
)}
            
            <Actions>
            </Actions>
          </Content>
          
        </LeftColumn>
     
        <RightColumn
  bgImage={process.env.REACT_APP_BACKEND_URL + `/recipes/${recipeRefresh._id}/image`}
  style={{
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    maxHeight: '60vh',
    backgroundColor: 'grey',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  }}
>
  <VariantButtonGroup recipe={recipeRefresh} />
</RightColumn>
        </TwoColumn>


    </Container>

    
  );
};
