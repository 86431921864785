import React, { useState, useEffect } from 'react';
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import axios from 'axios';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import Header from "components/headers/light.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import styled from "styled-components";
import GridFilter from 'components/cards/GridFilter';

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;

const Container = tw.div`relative`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;

const VerticalSpacer = tw.div`mt-10 w-full`



export default () => {
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [uniqueCategories, setUniqueCategories] = useState([]);

  const navigate = useNavigate();


  useEffect(() => {
    if (localStorage.getItem('postSuccess') === 'true') {
      
      setAlert(true);
      localStorage.removeItem('postSuccess');
    }
  }, []);

    useEffect(() => {
    const categories = recipes.flatMap(recipe => recipe.categories);
    const unique = [...new Set(categories)];
    setUniqueCategories(unique);
  }, [recipes]);


useEffect(() => {
  const access_token = localStorage.getItem('access_token');
  const fetchData = async () => {
    try {
      console.log('fetching data for user', process.env.REACT_APP_BACKEND_URL + '/recipes/user');
      const result = await axios.get(process.env.REACT_APP_BACKEND_URL + '/recipes/user' ,
      {
        headers: {
          'Authorization': `Bearer ${access_token}`
        }}
      );


      setRecipes(result.data);
      setLoading(false);  // Set loading to false when the data is fetched
    } catch (error) {
      console.error('Failed to fetch data', error);
      navigate('/login');
    }
  };

  fetchData();
}, [navigate]);

  if (loading) {
    return <div>Loading...</div>;  // Replace this with your actual loading component
  }

  return (
    <div>
    {alert && <Alert severity="success">Recipe inserted successfully!</Alert>}
    <AnimationRevealPage>
      <Header />
      
      {Object.keys(recipes).length !==0 &&
      <div>
      <GridFilter
        heading={
          <>
            Checkout your <HighlightedText>menus.</HighlightedText>
          </>
        }
        tabs={
          recipes
        }
        uniqueCategories = {uniqueCategories}
        from_my_recipes = {true}
        
        
      /> </div>}

      {Object.keys(recipes).length === 0 && <div><Container>
      <ThreeColumnContainer>
        {<Subheading>No recipes found</Subheading>}
        <Heading>Go insert some recipes!</Heading>
        <VerticalSpacer />
      
      </ThreeColumnContainer>
      </Container></div>}
      
       


     
      <Footer />
    </AnimationRevealPage>
    </div>)
}
